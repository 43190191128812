
@media only screen and (max-width: 890px) {
  .ant-menu .ant-menu-item {
    background: transparent !important;
  }
  
  .ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border-right: none !important;
  }
  
  .ant-menu-sub.ant-menu-inline {
    background: transparent !important;
  }
  
  .ant-menu-inline .ant-menu-item:after {
    border-right: none !important;
  }
}
